@import url("https://fonts.googleapis.com/css2?family=Concert+One&display=swap");

html {
  scroll-behavior: smooth !important;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  font-family: "Roboto", sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  height: 5px;
  /* margin-top: -12px; */
  margin-top: 0px;
  margin-bottom: 10px;
}

table {
  font-family: "Roboto", sans-serif !important;
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background: linear-gradient(135deg, rgb(49, 0, 62) 0%, rgb(195, 40, 110) 100%);
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.btn-pay:disabled {
  cursor: not-allowed;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.menu {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.ant-card-cover img {
  width: 100%;
  max-height: 3072px;
}

.zoom {
  height: inherit !important;
}

/* .masonry-container {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4 !important;
  display: block !important;
} */

.masonry-col {
  width: 100% !important;
  max-width: inherit !important;
  margin-bottom: 30px;
  page-break-inside: avoid;
}

.sort-container {
  width: 57%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.img-outer {
  max-width: inherit !important;
  max-height: 300px !important;
  text-align: center;
}

.menu {
  box-shadow: 0 4px 16px rgb(0 0 0 / 6%);
}

.masonry-col-row {
  padding: 20px 10px;
}

.ant-select-dropdown {
  padding: 0 !important;
  margin-top: -5px !important;
  border-radius: 5px !important;
}

.ant-select-item-option-active:hover {
  background-color: #dce6f0 !important;
}

.ant-select-item-option-selected {
  background-color: #dce6f0 !important;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: inherit !important;
  padding: 10px 15px !important;
}

.ant-select-item {
  padding: 10px 15px !important;
}

.container {
  width: 95%;
  max-width: 1920px;
  margin: 0 auto;
  /* padding: 0px 20px; */
  overflow: hidden;
}

.hero-heading {
  font-size: 50px;
  margin: 0;
}

.hero-subtext {
  font-size: 18px;
}

.hero-button {
  height: inherit !important;
  padding: 16px 40px !important;
  font-size: 19px !important;
  margin-top: 20px !important;
}

.hero-section {
  padding: 100px 0px;
  border-bottom: 1px solid #ededed;
}

.sort-content {
  display: flex;
  justify-content: flex-end;
}

.sort-content .ant-col {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding: 30px 0px 0px 0px;
}

.ant-menu-overflow {
  border: none !important;
}

.content-div {
  overflow: hidden;
}

.masonry-col-text {
  width: 70%;
  word-break: break-word;
}

.masonry-col-profile {
  width: 20%;
}

.ant-menu {
  width: 200% !important;
  overflow: hidden !important;
}

.ant-menu-submenu {
  width: auto !important;
}

.ant-menu-title-content a {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.ant-menu-title-content {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}

.login-btn a {
  color: white !important;
}

.ant-menu-item:hover .ant-menu-title-content a {
  color: black !important;
}

.login-btn {
  background-color: #1e73fa;
  border-radius: 30px;
  padding: 6px 14px !important;
}

.login-btn:hover {
  background-color: #055fec !important;
}

.login-btn::after {
  border-bottom: 0 !important;
}

.login-btn:hover .ant-menu-title-content a {
  color: white !important;
}

.load-more-btn {
  margin: 20px 0px;
}

.sign-in-up .ant-modal-header {
  border: 0;
  padding: 25px 50px;
  display: block;
  text-align: center;
}

.sign-in-up .ant-modal-footer {
  border: 0;
}

.sign-in-up .ant-modal-close {
  display: none;
}

.sign-in-up .ant-modal-title {
  font-size: 28px;
  line-height: normal;
  display: inline-block;
}

.sign-in-up .ant-form-item {
  flex-flow: column;
}
.sign-in-up-form-block .ant-form-item-control {
  width: 100%;
}
.google-sign {
  width: 100% !important;
  padding: 4px 100px !important;
  border: 2px solid #eaeaea !important;
  box-shadow: none !important;
  border-radius: 30px !important;
  margin: 25px 0px !important;
  opacity: 1 !important;
  justify-content: center;
}
.google-sign span {
  font-size: 16px;
}

.sign-or {
  position: relative;
}
.sign-in-up .ant-input {
  padding: 10px 11px;
}
.sign-in-up .ant-form-item {
  margin-bottom: 14px;
}

.sign-in-up .ant-modal-body {
  padding: 0px 50px;
}

.sign-or::before {
  content: "";
  position: absolute;
  width: 47%;
  height: 1px;
  background-color: #eaeaea;
  left: 0;
  top: 50%;
}

.sign-or::after {
  content: "";
  position: absolute;
  width: 47%;
  height: 1px;
  background-color: #eaeaea;
  right: 0;
  top: 50%;
}

.sign-form {
  margin-top: 50px;
}

.sign-form input {
  border: 0;
  padding: 0px 0px 10px 0px;
  border-bottom: 2px solid #1873e6;
  width: 100%;
  outline: none;
  margin-bottom: 30px;
}

.sign-in-up .ant-form-item-label {
  text-align: left;
}

.sign-in-up .ant-col-sm-8 {
  max-width: 100%;
}

.sign-in-up .ant-col-sm-16 {
  max-width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

.continue-btn {
  border-radius: 50px !important;
  font-weight: bold !important;
  display: inline-block;
  border: 1px solid;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #1890ff;
  padding: 14px 0px;
  height: inherit !important;
  transition: 0.3s all ease;
}
.sign-up-form .continue-btn {
  margin-top: 15px;
}
.continue-btn:hover {
  color: white;
  background-color: #40a9ff;
}

.create-btn {
  border: 0 !important;
}

.create-btn span {
  color: #1873e6;
}

.sign-up .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.sign-up-form {
  margin-top: 15px !important;
}

.hero-section {
  padding: 50px 0px;
  background-color: #5ccaf4;
}

.hero-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  border: 1px solid transparent;
  background-color: white;
  border-radius: 30px;
  width: 800px;
  padding: 30px 10px;
}

.hero-btn {
  background-color: #1e73fa !important;
  height: inherit !important;
  padding: 10px 25px !important;
  font-weight: bold !important;
  margin: 30px 0px;
}

.hero-btn:hover {
  background-color: #055fec !important;
}

.convert-img {
  border-radius: inherit;
}

.ant-tabs-nav-wrap {
  justify-content: flex-start !important;
}

.ant-tabs-nav {
  padding: 0px 60px;
}

.try-now-row {
  width: 80%;
  margin: 0 auto;
  padding: 100px 0px;
}

.try-now-box {
  padding: 32px 20px;

  border: 1px solid #eaeaea !important;
}

.try-now-box--top {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.6) !important;
  height: 55px;
}

.try-now-box--top .ant-image {
  margin-right: 10px;
}

.try-now-box--top img {
  border-radius: inherit !important;
  width: 40px !important;
}

.try-now-box p {
  margin: 20px 0px 40px 0px;
  color: rgba(0, 0, 0, 0.5) !important;
  height: 30px;
}

.try-now-box button {
  font-weight: bold;
  border: 2px solid;
}

.jump {
  position: fixed;
  right: 20px;
  bottom: 50px;
  z-index: 99999;
  padding: 2px;
  cursor: pointer;
  scroll-behavior: smooth;
}

.sort-row {
  padding: 0px 20px;
}

.minting-row {
  justify-content: space-between;
}

.minting-col {
  width: 50%;
}

.minting-col-right {
  width: 40%;
}

.minting-heading-row {
  padding: 30px 0px;
}

.heart-btn {
  padding: 6px 0px !important;
  margin-right: 10px;
}

.heart-count-btn {
  display: flex;
  align-items: center;
}

.masonry-col .ant-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.sign-up .ant-form-item-control-input-content {
  flex-flow: column;
}

.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: none;
}

/* .sign-up-terms {
  padding: 0px 0px 30px 0px;
} */

.sign-up-terms p {
  margin-bottom: 20px;
  line-height: 1.9;
}

.sign-up-terms .ant-checkbox-wrapper {
  margin-bottom: 10px;
}
.form-bottom {
  text-align: center;
  padding-top: 10px;
  border-top: 1px solid #ededed;
}
.form-bottom p {
  margin-bottom: 0;
}
.sign-up-btn-block .ant-col-sm-offset-8 {
  margin: 0 !important;
}
.admin-menu {
  width: inherit !important;
}

.owner-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-etc-box {
  box-shadow: 0px 0px 25px 14px rgba(184, 174, 174, 0.37);
  -webkit-box-shadow: 0px 0px 25px 14px rgba(184, 174, 174, 0.37);
  -moz-box-shadow: 0px 0px 25px 14px rgba(184, 174, 174, 0.37);
  padding: 0px 15px;
  border-radius: 17px;
}

.content-transfer-box {
  background-color: #fff;
  border-top: 1px solid #ededed;
  text-align: center;
  padding: 40px 0px;
}

.content-bottom-table {
  box-shadow: 5px 16px 30px 8px rgba(184, 174, 174, 0.37);
  -webkit-box-shadow: 5px 16px 30px 8px rgba(184, 174, 174, 0.37);
  -moz-box-shadow: 5px 16px 30px 8px rgba(184, 174, 174, 0.37);
  border-radius: 17px;
  padding: 15px 20px;
}

.content-bottom-table h2 {
  margin-left: 5px;
}

.content-transfer-box p {
  font-size: 18px;
}

.nft-icon {
  max-width: 150px;
  margin-bottom: 23px;
}

tr:nth-child(even) {
  background-color: #ededed;
}

.nft-img-preview {
  max-width: 100px !important;
  height: 95px !important;
}

.nft-preview-img-block {
  width: 30%;
}

.nft-preview-text {
  width: 70%;
}

.jacket-row {
  flex-flow: column !important;
  align-items: flex-start;
}

.image-popup-minting {
  margin-bottom: 20px;
}

.image-popup-minting .ant-image {
  width: 100%;
  height: 100%;
}

.image-popup-minting .ant-image img {
  max-height: 100px !important;
}

.minting-result-content {
  max-width: 400px;
  border: 1px solid transparent;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  text-align: start;
  margin: 0 auto;
}
.minting-result-img {
  border-radius: 10px;
  padding: 2px;
  border: 1px solid darkgrey;
  max-width: 350px;
  width: 100%;
  height: 250px;
}
.minting-result-img .ant-image {
  width: 100%;
  height: 100%;
}
.minting-result-img .ant-image {
  display: block;
}
.minting-result-img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.minting-result-img .ant-image-mask {
  border-radius: 10px;
}
.minting-result-heading {
  text-align: center;
  padding: 0px 20px;
  margin: 0;
  line-height: normal;
}
.minting-result-title {
  text-align: center;
  font-size: 21px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
  color: rgba(0, 0, 0, 0.85);
}
.minting-result-heading span {
  color: #1890ff;
}
.minting-result-hash {
  word-break: break-word;
}
.minting-result-outer {
  max-width: 500px;
  margin: 0 auto;
  background-color: rgba(24, 26, 32, 1);
  padding: 30px 0px;
}
.minting-result-details {
  /* border: 1px solid #d3d3d3;
  background-color: #ededed;
  border: 3px solid rgba(24, 26, 32, 1);
  padding: 20px; */
  border-radius: 20px;
  padding: 10px;
  margin-top: 30px;
  flex-flow: column !important;
}
.minting-result-heading-col {
  width: 45%;
}
.minting-result-status-col {
  width: 55%;
}
.minting-result-details-col {
  display: flex;
}
.minting-result-details p {
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
}
.minting-result-details-heading {
  font-weight: bold;
  margin-bottom: 10px !important;
}
.minting-result-social-heading {
  font-size: 22px;
  font-weight: bold;
}
.minting-result-social-col {
  text-align: center;
}
.minting-result-social-icons {
  display: flex;
  justify-content: space-between;
}
.minting-result-social-icons button {
  margin-right: 10px;
}
.minting-result-social-icons button:last-child {
  margin: 0;
}
.minting-result-btns {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 30px;
}
.minting-result-btns button {
  margin-bottom: 10px;
  padding: 5px 20px;
  font-size: 16px;
  height: auto !important;
  min-height: 50px;
  font-weight: 500;
}
.nft-certificate {
  font-size: 22px;
  font-weight: 400;
  font-family: "Kanit", sans-serif !important;
}
.nft-stamp-img {
  margin-bottom: 20px;
  max-width: 62px;
}
.minting-result-certificate {
  display: flex;
  align-items: center;
}
.minting-result-certificate .ant-image {
  width: inherit !important;
}
.certificate {
  font-size: 2.2rem;
  font-weight: bold;
  margin-left: 10px;
  color: #3f3f3f;
  margin-left: 0;
  font-family: "Concert One", cursive;
  letter-spacing: 1px;
}
.top-row {
  padding: 0 !important;
}
.ham-menu {
  font-size: 20px !important;
}
.details-image-block {
  display: flex;
  flex-flow: row;
  justify-content: center;
}
.details-image {
  max-width: 100%;
}
.header-menu-ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.header-menu-ul li {
  display: block;
}
.header-btns-li {
  display: flex !important;
  align-items: center;
}

.header-menu-ul a {
  font-weight: 500;
  font-size: 16px;
  /* color: rgba(0, 0, 0, 0.5); */
  color: black;
  padding: 14px 16px;
  display: inline-block;
}
.hamburger-btn {
  display: none;
  font-size: 22px;
}
.sign-in-out {
  transition: all 0.3s;
  background-color: #1e73fa;
  border-radius: 4px;
}
.login-btn {
  color: white !important;
}
.right-menu {
  position: inherit !important;
}
.menu-inner {
  position: relative;
  padding: 5px 0px;
}
.header-menu-show {
  display: flex !important;
}
.header-row {
  align-items: center;
}
.menu .container {
  overflow: visible;
}
.service-ul,.dropdown-menu {
  display: none !important;
  flex-flow: column;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  width: 200px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 6%);
}
.service-ul-show,.menu-show {
  display: flex !important;
}
.service-li {
  display: flex !important;
  flex-flow: column;
  position: relative;
}
.service-li:hover .service-ul {
  display: flex;
}
.service-li-title,.submenu-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-btn:first-child {
  margin-right: 15px;
}
.flag-icon {
  max-width: 25px;
  margin-left: 10px;
  width: 100%;
}
.flag-link {
  display: flex !important;
  align-items: center;
}
.japan-flag {
  border: 1px solid #ededed;
  margin-left: 17px !important;
}
/* .footer-bottom {
  border-top: 3px solid white;
  padding-top: 20px;
  margin-top: 20px;
} */
.footer-bottom h4 {
  margin: 0;
}
.header-arrow {
  color: black !important;
}
.sign-in-btn {
  background-color: transparent;
  border: 1px solid #1e73fa;
  color: #1e73fa !important;
}
.sign-in-btn:hover {
  color: white !important;
}
/* .logo {
  max-width: 200px;
} */
.border-bottom {
  width: 100%;
  height: 1px;
  background-color: #ededed;
  display: none;
}

/* skeleton */
.skeleton-block {
  width: 100%;
}
.masonry-col .skeleton-block {
  max-width: 300px;
  margin: 0 auto;
}
.skeleton-image {
  width: 100% !important;
  min-height: 200px;
}
.detail-page-content .skeleton-image {
  min-height: 400px;
}
.detail-page-content .skeleton-block {
  padding: 40px 0px;
}
/* about-us page */

/* faq page */
.faq-content {
  padding: 40px 0px;
}
.faq-ol li::marker {
  font-size: 22px;
  font-weight: bold;
}
.faq-questions {
  margin-top: 50px;
}
.faq-ques {
  font-size: 22px;
  font-weight: bold;
}
.faq-ans {
  font-size: 20px;
}
.page-heading {
  font-size: 30px;
}
.no-content {
  width: 100%;
}
.content {
  padding: 40px 0px;
  min-height: calc(100vh - 155.14px) !important;
}

/* hourglass */

.minting-image-loader-wrapper {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 9;
}
.hourglass {
  display: block;
  background: white;
  margin: 3em auto;
  width: 3em;
  height: 5em;
  animation: hourglass 1s linear infinite;
}

.outer {
  fill: #1e73fa;
}

.middle {
  fill: white;
}

@keyframes hourglass {
  0% {
    transform: rotate(0deg);
    box-shadow: inset #1e73fa 0 -0em 0 0, inset white 0 -2em 0 0, inset #1e73fa 0 -4em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset #1e73fa 0 -2em 0 0, inset white 0 -2em 0 0, inset #1e73fa 0 -2em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset #1e73fa 0 -2em 0 0, inset white 0 -2em 0 0, inset #1e73fa 0 -2em 0 0;
  }
}

@media (max-width: 1440px) {
  .menu .container {
    width: 90%;
  }
}
@media (max-width: 1245.9px) {
  .menu .container {
    width: 100%;
  }
  .try-now-box {
    flex: 0 0 40% !important;
    max-width: 40% !important;
    margin-bottom: 30px;
  }
  .header-menu-ul a {
    padding: 12px 10px;
    font-size: 14px;
  }
}

@media (max-width: 991.5px) {
  .masonry-container {
    column-count: 3 !important;
  }
  .details-image-block {
    padding: 50px 0;
  }

  .container {
    width: 100%;
  }
  .details-image {
    width: 100%;
  }
  /* .logo {
    max-width: 200px;
  } */
  .details-table {
    display: flex;
    width: auto;
  }
  .details-table-heading-row {
    display: flex;
    flex-flow: column;
  }
  .details-table-heading-row th {
    min-height: 40px;
  }
  .details-table-content-row {
    display: flex;
    flex-flow: column;
  }
  .details-table-content-row td {
    min-height: 40px;
  }
  .content-bottom-table {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .hamburger-btn {
    display: block;
  }
  .header-menu-ul {
    display: none;
    top: 120%;
    left: 0;
    position: absolute;
    flex-flow: column;
    background-color: white;
    width: 100%;
    align-items: center;
    padding: 10px 25px 40px 25px;
    border: 1px solid #ededed;
    align-items: inherit;
  }
  .service-li {
    display: block !important;
    width: 100% !important;
  }
  .service-li:hover .service-ul {
    display: inherit;
  }
  .service-ul {
    position: inherit !important;
    padding-left: 15px;
    box-shadow: none;
  }
  .header-btns-li {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .login-btn {
    width: 48%;
    text-align: center;
  }
  .footer-top {
    display: none !important;
  }
  .footer-bottom {
    border-top: 0;
    padding-top: 0;
    margin-top: 0;
  }
  .logout-only-btn {
    width: 100%;
    margin-right: 0 !important;
  }
  .header-menu-ul a {
    font-size: 16px;
  }
  .border-bottom {
    display: block !important;
  }
  .header-menu .header-notify{
    margin-left: 15px;
  }
}

@media (max-width: 768px) {
  .masonry-container {
    column-count: 2 !important;
  }

  .ant-menu {
    width: auto !important;
  }

  .sort-col {
    padding: 0 5px !important;
  }

  .sort-row {
    justify-content: center !important;
    padding: 0;
  }

  .home-tabs .ant-tabs-nav {
    padding: 0 !important;
  }

  .home-tab:first-child {
    margin: 0;
  }

  .home-tab {
    margin: 0 0 0 12px;
  }

  .ant-tabs-nav-wrap {
    justify-content: space-around !important;
  }

  .home-tab font {
    font-size: 14px;
  }

  .minting-row {
    flex-flow: column !important;
  }

  .minting-col {
    width: 100%;
  }

  .try-now-box {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-bottom: 30px;
  }

  .google-sign {
    padding: 4px 15px !important;
  }

  .sign-in-up .ant-modal-body {
    padding: 0px 20px;
  }

  .sign-in-up .ant-modal-header {
    padding: 25px 20px;
  }

  .faq-ol li::marker {
    font-size: 18px;
    font-weight: bold;
  }
  .faq-questions {
    margin-top: 50px;
  }
  .faq-ques {
    font-size: 18px;
    font-weight: bold;
  }
  .faq-ans {
    font-size: 16px;
  }
  .page-heading {
    font-size: 26px;
  }
}

@media (max-width: 449px) {
  .masonry-container {
    column-count: 1 !important;
  }
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;

  width: 100%;
  flex-flow: row wrap;
}

.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

/* .ant-row-center {
  padding-top: 5px;
} */

@media (max-width: 576px) {
  .minting-result-content {
    /* padding: 0; */
    max-width: 320px;
  }
  .minting-result-outer {
    background-color: white;
  }
  .minting-result-heading {
    padding: 0;
  }
}

/* Admin Pages */

.admin__wrapper {
  min-height: calc(100vh - 185px);
  padding: 3rem 0;
}

.adminForm,
.notificationManage_outer {
  padding: 2rem 3rem;
}

.adminForm .ant-page-header {
  padding-left: 0;
}

.notificationEditor_wrap {
  border: 1px solid #ddd;
  background: #fff;
}

.notificationEditor_wrap .rdw-editor-toolbar {
  border: 0px;
  margin-bottom: 0px;
}

.notificationEditor {
  min-height: 180px;
  max-height: 180px;
  overflow: auto;
  padding: 10px;
}

.pl-0 {
  padding-left: 0 !important;
}
.categoryOuterWrap {
  width: 100%;
  max-width: calc(100% - 16.66666667% * 2);
  display: inline-flex;
  justify-content: space-around;
  flex-flow: wrap;
}
button.category_btn {
  height: auto;
  padding: 3px 10px;
  border: 0.13rem solid #d9d9d9;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
button.category_btn font {
  transition: linear 0.2s;
}
button.category_btn:hover,
button.category_btn:focus,
button.category_btn:active {
  border-color: #1e74fa !important;
}

/* detail  */
.viewsAndLikes {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: wrap;
}
.likesOuter,
.like_wrap {
  display: inline-flex;
}
.like_wrap {
  flex-flow: wrap;
  width: 100%;
  padding-bottom: 20px;
  align-items: center;
}
.like_wrap:last-child {
  padding-bottom: 0;
}
.likesOuter > span:nth-child(2) {
  padding: 0 9px;
  text-align: center;
}
.like_wrap--icon {
  font-size: 25px;
  line-height: 100%;
  width: 40px;
  color: var(--antd-wave-shadow-color);
}
.like_wrap--data {
  width: calc(100% - 40px);
  display: flex;
  flex-flow: wrap;
}
.like_wrap--data b {
  font-size: 12px;
  width: 100%;
}
.commentOuter,
.replyOuter .ant-comment-inner {
  position: relative;
}
.replyOuter > p {
  padding-left: 30px;
}
.commentOuter:before,
.replyOuter .ant-comment-inner:before {
  content: "";
  position: absolute;
  top: 55px;
  left: 15px;
  background: #eee;
  width: 2px;
  height: calc(100% - 65px);
}

.MetaIconOuter {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  width: 100%;
}

.commentIcon {
  margin-right: 6px;
  font-size: 16px;
}

.CommentOuter {
  margin-left: 3px;
}

.CommentOuter,
.GiftOuter,
.ShareOuter {
  display: inline-flex;
  align-items: center;
  padding: 0 6px;
}

.likesOuter .anticon-dislike {
  top: 0.125rem;
  position: relative;
}

.comentTitle {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.postComentForm {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.comentAction {
  margin-left: 15px;
  display: inline-flex;
  flex-flow: wrap;
  justify-content: space-between;
  width: 200px;
}

.comenTextarea.ant-input {
  width: calc(100% - 215px);
  border-radius: 5px;
}

.comentAction .ant-btn {
  width: calc(100% / 2 - 5px);
  min-width: 80px;
}

.comentAction .transparetnBtn {
  border: 0px;
  background: transparent;
  transition: linear 0.2s;
}

.comentAction .transparetnBtn:hover,
.comentAction .transparetnBtn:focus,
.comentAction .transparetnBtn:active {
  background: #eee;
  color: #000;
}

.comentAction button.ant-btn-primary {
  background: #1e74fa;
  border-color: #1e74fa;
}

.comentAction button.ant-btn-primary:hover,
.comentAction button.ant-btn-primary:focus,
.comentAction button.ant-btn-primary:active {
  background: #055fec;
  border-color: #055fec;
}

.ant-comment-content .ant-comment-actions li:last-child {
  margin-left: 8px;
}


/* Header Submenu */
.header-menu {
  display: flex;
  align-items: center;
}

.header-menu-ul li{
  position: relative;
}

.header-menu-ul li{
  position: relative;
}

.header-menu-ul li.submenu-li {
  display: flex;
}

.header-menu-ul li.submenu-li>a {
  padding-right: 5px;
}

/* Notification List */
.header-menu .header-notify{
  position: relative;
}

.header-notify .header-notify{
  margin-left: 0;
}

.header-notify .notification-list {
  position: absolute;
  right: 0;
  min-width: 320px;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #eee;
  top: 100%;
  max-height: 80vh;
  overflow: auto;
  box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition:linear .2s;
}

.header-notify .notification-list.show {
  opacity: 1;
  visibility: visible;
}

.notification-list .ant-list-item, 
.notification-list .ant-list-item-meta,
.notification-list .ant-list-item-action {
  flex-flow: wrap;
  width: 100%;
  flex-basis: 100%;
  margin: 0;
}

.notification-list .ant-list-item{
  padding: 5px 7px;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
  border:0px;
  border-radius: 6px;
}

.notification-list .ant-skeleton {
  display: inline-flex;
  padding: 10px 10px 0px;
  border: 1px solid #eee;
  margin-bottom: 8px;
  border-radius: 6px;
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.1);
}

.notification-list .ant-skeleton-avatar,
.notification-list .ant-skeleton-content .ant-skeleton-paragraph > li{
  border: 1px solid #1890ff;
}

.notification-list .ant-list-item + .ant-list-item{
  margin-top: 8px;
}

.notification-list .ant-list-item-meta-title{
  font-size: 13px;
  margin-bottom: 0;
  transition:linear .2s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 110px);
}

.notification-list .ant-list-item-meta-description{
  font-size: 12px;
  line-height: 16px;
  transition:linear .2s;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-link {
  width: 100%;
  position: relative;
}

.notification-link:hover .ant-list-item-meta-title,
.notification-link:hover .ant-list-item-meta-description{
  color:#1e73fa;
}

.noData-outer {
  display: inline-flex;
  flex-flow: wrap;
  justify-content: center;
  padding: 10px;
  margin-top: 8px;
  width: 100%;
}

.noData-outer .title {
  margin: 0;
  font-size: 17px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.noData-outer p {
  margin: 0;
  font-size: 13px;
}

.noData-outer a {
    margin-top: 10px;
}


/* .sort-row {
  margin-bottom: 15px;
} */

#top-scroll .ant-row + .ant-row >div {
  clear: both;
}

#top-scroll .ant-row-end .ant-col.sort-col:last-child {
  padding-right: 0 !important;
}

#top-scroll .ant-row.sort-row+.ant-row, 
#top-scroll .ant-row .ant-row.masonry-container{
  margin-top:0!important;
  margin-bottom: 0!important;
}

.signup-footer{
  display: inline-block;
  margin-bottom: 15px;
}

.my-profile-content button.google-sign {
    padding: 4px 10px !important;
    justify-content: center;
}

.my-profile-content button.google-sign {
    padding: 4px 10px !important;
    justify-content: center;
}

.mainContentRow {
    margin-bottom: 25px;
}

.userDetail {
    display: inline-flex;
    align-items: center;
    flex-flow: wrap;
    width: 100%;
    margin-bottom: 15px;
}

.userDetail h2 {
    margin: 0 20px 0px 0px;
    max-width: 240px;
    width: 100%;
}

.edit-btn.ant-btn {
    border-radius: 030px;
    min-height: 40px;
    font-weight: 500;
    letter-spacing: .5px;
    padding: 0 20px;
}

.sign-up-form .fpSubmitBtn {
    min-height: 40px;
    font-weight: 500;
    width: 100%;
    border-radius: 30px;
    letter-spacing: .5px;
    text-transform: uppercase;
}

.userProfileAction {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.userProfileAction .google-sign {
  height: 55px;
  max-width: 280px;
}

.userProfileAction .ant-btn.edit-btn {
  min-height: 54px;
  margin-right: 10px;
  width: 200px;
}

/* Events Page */

/* .events__outer.star_points .ant-collapse > .ant-collapse-item > .ant-collapse-header>div:not(.points_outer){
  display: none;
} */

.events__outer.star_points .ant-collapse > .ant-collapse-item > .ant-collapse-header{
  display: flex;
  width: fit-content;
  margin: 0 auto;
}

.events__outer.star_points .ant-collapse > .ant-collapse-item > .ant-collapse-header>div.points__outer,
.events__outer.star_points .ant-collapse > .ant-collapse-item > .ant-collapse-header>div.points__outer {
  font-size: 20px;
  font-weight: 600;
  color: #222;
  padding: 0 10px 0 0!important;
  text-align: center;
  width: 100% ;
  border: 0 ;
  position: static ;
  padding: 0;
  display:flex;
}

.events__outer.star_points .ant-collapse > .ant-collapse-item ,
.events__outer.star_points .ant-collapse > .ant-collapse-item > .ant-collapse-header,
.events__outer.star_points .ant-collapse-item:last-child > .ant-collapse-content{
  border:0;
  background: transparent;
}

.events__outer.star_points .ant-collapse .ant-collapse-item.ant-collapse-item-active .circelDownIcon{
  transform:rotate(180deg)
}

.circelDownIcon {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  background-blend-mode: color;
  border: .125rem solid #333;
  border-radius: 50%;
  margin-left: 5px;
  transition:linear .2s;
  transform:rotate(0deg)
}

.circelDownIcon img {
  height: 14px;
  position: relative;
  left: -1px;
}

.events__outer{
  margin-bottom: 40px;
}

.events__outer > .heading{
  font-size: 1.25rem;
}

.events__outer .ant-collapse > .ant-collapse-item > .ant-collapse-header > div, 
.events__outer .ant-collapse > .ant-collapse-item > .ant-collapse-header > span.ant-collapse-arrow {
  position: absolute;
  right: 0;
  /* width: 16px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  /* border: .125rem solid #333; */
  border-radius: 50%;
  margin: auto 10px auto 0 ;
  top: 0;
  bottom: 0;
}

.events__outer .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  font-weight: 600;
  font-size: 16px;
}

.events__outer .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin: 0;
  font-size: 8px;
  font-weight: bolder;
  transform: rotate(90deg);
  color: #000;
}

.events__outer .ant-collapse {
  border: 0;
  background: transparent;
}

.events__outer .ant-collapse > .ant-collapse-item{
  margin-bottom: 15px;
  border:2px solid #eee;
  background: #f1f1f1;
}


.events__outer .ant-collapse > .ant-collapse-item,
.events__outer .ant-collapse-content > .ant-collapse-content-box{
  border-radius: 10px!important;
}

.events__outer .ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(-180deg) !important;
}

.events__outer .ant-pagination-item-active a,
.events__outer .ant-pagination-item-active {
  color: #1e73fa;
  border-color: #1e73fa;
  box-shadow: none;
}

.myToken__outer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.token {
  background: #fff;
  padding: 9px 18px;
  border-radius: 30px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.token .icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.token_count {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.token_count small {
  font-size: 14px;
  font-weight: normal;
  color: #000;
  width: 100%;
  line-height: 100%;
}

.token_count a {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 100%;
  padding: 5px 0 0 0;
  color: #000;
}


.eventPopup.ant-modal {
  max-width: 770px;
  width: calc(100% - 20px)!important;
}

.eventPopup.ant-modal .ant-modal-content{
  border:  2px solid #c0c0c0;
  box-shadow: none;
}

.eventPopup .ant-modal-header{
  border:0px;
  padding-bottom: 0;
  padding-top: 30px;
}

.eventPopup .ant-modal-body {
  padding: 15px 60px 30px;
}

.eventPopup .ant-modal-title {
  color: #000;
  line-height: 1.4;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 25px;
}

.eventPopup .ant-typography,.modalContent,.modalContent p {
  color: #000;
  overflow-wrap: break-word;
  font-size: 18px;
  font-weight: 600;
}

.eventPopup .ant-modal-btns .ant-btn-primary {
  border-radius: 4px;
  font-weight: 500;
}

.eventPopup .ant-btn-primary{
  background-color:#6e86f3;
  height:auto;
  font-size:16px;
  font-weight: 600;
  padding: 5px 20px;
  border-color: transparent;
}

.eventPopup .ant-modal-body > .anticon{
  display: none;
}

.eventPopup.ant-modal .ant-modal-btns{
  margin-top: 1em;
  text-align: right;
}

.eventPopup .ant-modal-footer {
  border-top: 2px solid #c0c0c0;
  background: #ddd;
  padding: 10px 40px;
  display: flex;
}

.confirm__outer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap;
}

.confirm__outer .ant-checkbox-wrapper {
  font-size: 18px;
  font-weight: 600;
  color: #ffff;
  text-align: left;
    line-height: normal;
}

.confirm__outer .ant-checkbox-wrapper input.ant-checkbox-input,
.confirm__outer .ant-checkbox-wrapper span.ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.confirm__outer button.ant-btn {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
}

.confirm__outer .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 6px;
  height: 12px;
  margin: 0 0 0 1px;
}

#top-scroll .ant-card-body .ant-col {
  width: 100%;
}

.socialMediaShare {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 1;
  left: calc(50% - 102px);
  max-width: 200px;
  min-width: 200px;
  margin: auto;
  background: #fff;
  border: 1px solid #c4c4c4;
  padding: 5px 15px;
  border-radius: 11px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition:linear .2s;
  transform: translateY(-6px);
  opacity: 0;
  visibility: hidden;
}


.ShareOuter.show .socialMediaShare {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}


.ShareOuter{
  position: relative;
}

.ShareOuter.show:before {
  position: absolute;
  content: '';
  top: 14px;
  border-bottom: 10px solid #c5c5c5;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 6px;
  width: 0;
  height: 0;
}

.like_wrap .socialMediaShare {
    left: -10px;
}

.ShareOuter.like_wrap.show:before {
    top: 27px;
    left: 10px;
}

.socialMediaShare button{
  margin: 0 5px;
}

.socialMediaShare button, .socialMediaShare svg {
  width: 22px;
  height: 35px;
}

.copy_outer {
  cursor: pointer;
  height: 46px;
  min-width: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #33aced;
}

.copy_outer svg {
  stroke: none;
  fill: #fff;
  width: 23px;
  height: 23px;
}

.socialMediaShare .copy_outer{
  height: 22px;
  min-width: 22px;
}

.socialMediaShare .copy_outer svg {
  width: 12px;
}

.notice__description img {
    max-width: 100%;
    height: auto !important;
}

.events__outer .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  flex-flow: wrap;
  display: flex;
  justify-content: flex-start;
  line-height: 130%;
}

.events__outer .ant-collapse > .ant-collapse-item > .ant-collapse-header > div.ant-collapse-extra {
  position: static;
  margin-left: auto;
  height: auto;
}

.eventPopup .ant-typography.ant-typography-ellipsis.ant-typography-ellipsis-multiple-line {
	max-height: 300px;
	overflow: hidden;
}

/* Media Query */

@media (max-width: 1199.98px) {
  button.category_btn {
    padding: 3px 8px;
  }
  button.category_btn font {
    font-size: 14px;
  }
}

@media (max-width: 991.98px) {
  .categoryOuterWrap {
    max-width: 100%;
    padding: 0 10%;
  }
  .detailImgBlock {
    order: -1;
  }
  .detailImgBlock .details-image-block {
    padding-top: 0px;
  }
  .detailLeftBlock {
    margin-bottom: 40px;
  }
  .menu{
    z-index: 100000;
  }
  .dropdown-menu {
    position: relative;
    width: 100%;
    box-shadow: none;
  }
  .header-menu-ul li{
    text-align: center;
    width: 100%;
    flex-flow: wrap;
  }
  .service-ul, .dropdown-menu  {
    margin-left: auto;
    margin-right: auto;
  }
  .header-menu-ul{
    overflow:auto;
    max-height:calc(100vh - 56px);
    height:calc(100vh - 56px);
    flex-flow: wrap;
    top: 100%;
    align-items: flex-start;
    align-content: flex-start;

  }
  .header-menu-ul a{
    display: inline-flex!important;
    width: auto;
  }
  .header-menu-ul a.logout-only-btn {
    margin: 10px auto 0!important;
  }
  /* .header-menu-ul li.service-li, 
  .header-menu-ul li.submenu-li{
  border-bottom:1px solid #ededed;
  }*/

  .service-li-title, .submenu-li{
    justify-content: center;
    padding-left: 0;
  }

  .header-menu-ul ul a{
    font-size: 14px;
    color: #333;
  }

    .ShareOuter.like_wrap.show .socialMediaShare {
        position: absolute;
        left: auto;
    }

}

@media (max-width: 767.98px) {
  button.category_btn {
    width: calc(100% / 3 - 10px);
    display: flex;
    justify-content: center;
  }
  .replyOuter p + div {
    margin-left: 20px !important;
    width: calc(100% - 20px) !important;
  }
  .commentOuter .ant-comment-avatar {
    margin-right: 5px;
  }
  .main__title.ant-typography {
    font-size: 1.5rem;
  }
  .minting-result-outer {
    padding: 50px 0;
  }
}

@media (max-width: 575.98px) {
  button.category_btn {
    width: calc(100% / 2 - 10px);
    display: flex;
    justify-content: center;
  }

  .comenTextarea.ant-input {
    width: 100%;
    margin-bottom: 15px;
  }

  .comentAction {
    margin-left: 0;
  }

  .header-notify .notification-list{
    min-width: 300px;
    right: -10px;
  }

  .userDetail h2{
      max-width: 100%;
  }

  .userProfileAction .google-sign {
    max-width: 240px;
  }
  .userProfileAction .ant-btn.edit-btn {
    width:190px;
  }
  .eventPopup .ant-modal-body,
  .eventPopup .ant-modal-footer {
    padding: 10px 15px;
  }
  .eventPopup .ant-modal-title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  .eventPopup .ant-typography, 
  .confirm__outer button.ant-btn,
  .modalContent, .modalContent p,.confirm__outer .ant-checkbox-wrapper {
    font-size: 16px;
  }
  .confirm__outer button.ant-btn {
    font-size: 18px;
    margin: 5px auto;
  }
  .confirm__outer .ant-checkbox-wrapper input.ant-checkbox-input,
  .confirm__outer .ant-checkbox-wrapper span.ant-checkbox-inner {
    width: 17px;
    height: 17px;
  }
  .eventPopup .ant-modal-close, .eventPopup .ant-modal-close-x {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .eventPopup .ant-modal-header{
    padding:30px 15px 0 15px;
    text-align: left;
  }

  .modalContent, .modalContent p,
  .modalContent .ant-typography p{
    margin-bottom: .5em;
    font-weight: 500;
    line-height: 1.3;
  }
}

@media (max-width:479.98px){
  .userProfileAction .google-sign,
  .userProfileAction .ant-btn.edit-btn {
      max-width: 100%;
      width: 100%;
  }
}

@media (max-width:360px){
  .logo {
    max-width: 231px;
    display: inline-flex;
}
}

/* Page loader */
.page-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
    overflow: hidden;
}

.minting-image-loader-wrapper{
    background: transparent ;
}

.onExpandText {
    min-width: 100px;
}
